import React from 'react';

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Navbar,
  Container,
  Col,
} from 'react-bootstrap';

import 'assets/css/login.css';
import DNS from '../components/DNS/dns.js';
import {useState} from 'react';
import NotificationAlert from 'react-notification-alert';
import {useDispatch} from 'react-redux';
import Cookies from 'universal-cookie';

import {
  setToken,
  setBuilding,
  setCurrentBuilding,
  setUser,
} from '../actions/index';


/**
 * This function handle the style and js in the Login's page
 * @function Login
 * @return {HTML} Login's page
 */
const Login = () => {
  const [usernameField, setUsername] = useState('');
  const [passwordField, setPassword] = useState('');
  const [logError, setLogError] = useState(false);
  const dispatch = useDispatch();

  const cookies = new Cookies();

  const requestLogin = (e) => {
    e.preventDefault();
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({username: usernameField, password: passwordField}),
    };
    fetch(DNS + '/api/token-auth/', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data['token']) {
            setLogError(false);
            dispatch(setToken(data['token']));
            const requestUserInfo = {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'token ' + data['token'],
              },
            };
            fetch(DNS + '/api/auth/deleteGetPatchUser/', requestUserInfo)
                .then((response) => response.json())
                .then((data) => {
                  dispatch(setBuilding(data));
                  dispatch(setCurrentBuilding(data[0]));
                  fetch(DNS + '/api/auth/getUserInfo/', requestUserInfo)
                      .then((response) => response.json())
                      .then((data) => {
                        console.log('jughfeakljfka', data);
                        dispatch(setUser(data));
                        window.location.reload();
                      });
                });
          } else {
            setLogError(true);
          }
        });
    setPassword('');
    return;
  };

  const notificationAlertRef = React.useRef(null);
  const notify = (place) => {
    const type = 'danger';
    let options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            <p>
              Votre nom d'utilisateur ou mot de passe est incorrect. Veuillez le
              vérifier.
            </p>
          </div>
        </div>
      ),
      type: type,
      icon: 'nc-icon nc-bell-55',
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
    setLogError(false);
  };

  // pour la navbar
  const collapseOpen = false;

  // pour le formulaire de connexion
  const [cardClasses, setCardClasses] = React.useState('card-hidden');
  React.useEffect(() => {
    console.log(window.location.href);
    setTimeout(function() {
      setCardClasses('');
    }, 1000);
  });

  return (
    <>
      <Navbar
        className="position-absolute w-100"
        expand="lg"
        variant={collapseOpen ? 'white' : 'transparent'}
        style={{
          paddingTop: '15px',
          backgroundColor: 'initial',
          borderBottom: '1px solid transparent',
          zIndex: '999',
        }}
      >
        <Container>
          <div className="navbar-wrapper">
            <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
              <img src={require('assets/img/logo-havea.png').default} />
            </Navbar.Brand>
          </div>
        </Container>
      </Navbar>

      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require('assets/img/Immeuble-client.jpg').default}
      >
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="4" md="8">
              <Form onSubmit={requestLogin} className="form" method="">
                {logError == true ? notify('tc') : ''}
                <Card className={'card-login ' + cardClasses}>
                  <Card.Header>
                    <h3 className="header text-center">Login</h3>
                  </Card.Header>
                  <Card.Body>
                    <Card.Body>
                      <Form.Group>
                        <label>Nom d'utilisateur</label>
                        <Form.Control
                          placeholder="Nom d'utilisateur"
                          type="username"
                          onChange={(e) => setUsername(e.currentTarget.value)}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <label>Mot de passe</label>
                        <Form.Control
                          placeholder="Password"
                          type="password"
                          onChange={(e) => setPassword(e.currentTarget.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card.Body>
                  <Card.Footer className="ml-auto mr-auto">
                    <Button
                      className="btn-wd btn-round"
                      type="submit"
                      variant="warning"
                    >
                      Se Connecter
                    </Button>
                    <br />
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              'url(' + require('assets/img/background-login.jpg').default + ')',
          }}
        ></div>

        <footer
          className="footer position-absolute fixed-bottom"
          style={{
            position: 'relative',
            zIndex: '2',
            background: 'transparent',
          }}
        >
          <div className="container">
            <nav>
              <p className="text-center m-0" style={{color: 'white'}}>
                © {new Date().getFullYear()}{' '}
                Property of <a href="http://www.haveagooday.com/">Haveagooday</a>, made by WelcomeData.
              </p>
            </nav>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Login;
