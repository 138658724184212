import 'assets/css/HAGD-Custom.css';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DNS from '../components/DNS/dns.js';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

// react-bootstrap components
import {
  Button,
  Card,
  Modal,
  Form,
  Table,
  Container,
  Row,
  Col,
} from 'react-bootstrap';

const UserList = () => {
  const token = useSelector((state) => state.token);
  const user = useSelector((state) => state.user.user);
  const currentBuilding = useSelector((state) => state.currentBuilding);
  const animatedComponents = makeAnimated();

  const [showModal, setShowModal] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);
  const [showApPk, setApPk] = useState('');

  const [showEmail, setEmail] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showUsername, setUsername] = useState('');
  const [showFname, setFname] = useState('');
  const [showLname, setLname] = useState('');

  const [showTel, setTel] = useState('');
  
  const [showPassword, setPassword] = useState('');
  const [showAdmin, setAdmin] = useState(false);
  const [showAccueil, setAccueil] = useState(false);
  const [showCollab, setCollab] = useState(false);
  const [showSuperCollab, setSuperCollab] = useState(false);
  const [showRole, setRole] = useState('');

  const [showEntreprise, setEntreprise] = useState([]);
  const [showEntrepriseSelected, setEntrepriseSelected] = useState([]);
  const [showBatiment, setBatiment] = useState([]);
  const [showBatimentSelected, setBatimentSelected] = useState([]);
  const [showUserBatiment, setUserBatiment] = useState([]);

  const [validated, setValidated] = useState(false);
  const [alist, setList] = useState(
      {
        data: null,
        error: false,
      },
      [],
  );
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token['tokenRedux'],
    },
  };
  useEffect(() => {
    // let La_Variable_De_Ethan = "Employé chez Imperiatec"
    // console.log("Variable:", La_Variable_De_Ethan)
    // getEntreprise();
    console.log(user.role)
    getBatiment();
    getUserList();
  }, []);

  const requestOptionsAxiosGet = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token['tokenRedux'],
    },
  };


  const resetValues = () => {
    setApPk('');
    setEmail('');
    setUsername('');
    setLname('');
    setFname('');
    setCollab(false);
    setSuperCollab(false);
    setAccueil(false);
    setAdmin(false);
    setTel('');
    setPassword('');
    setEntrepriseSelected([]);
  };


  const getUserList = () => {
    fetch(
        DNS +
        '/api/auth/',
        requestOptions,
    ).then(async (response) => {
      const data = await response.json();
      console.log(data)
      if (data) {
        setList({
          data: data,
          error: false,
        });
        return;
      } else {
        setList({
          data: data,
          error: false,
        });
        return;
      }
    });
  };


  const getBatiment = () => {
    axios
        .get(
            DNS +
          '/api/building/',
            requestOptionsAxiosGet,
        )
        .then((response) => {
          // console.log(response)
          let tmp = [];
          response.data.map((elem) => {
            tmp = tmp.concat({'value': elem.id, 'label': elem.name});
          });
          setBatiment(tmp);
        });
  };



  const deleteUser = (user) => {
    console.log(token['tokenRedux']);
    const requestOptionsDelete = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + token['tokenRedux'],
      },
      body: JSON.stringify({pk: user}),
    };
    fetch(
        DNS +
        '/api/auth/deleteGetPatchUser/',
        requestOptionsDelete,
    ).then(async (response) => {
      const data = await response.json();
      console.log(data);
      getUserList();
      return;
    });
  };
  const addUser = () => {
    const requestOptionsPost = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + token['tokenRedux'],
      },
      body: JSON.stringify({
        username: showFname + ' ' + showLname,
        first_name: showFname,
        last_name: showLname,
        phone: showTel,
        email: showEmail,
        is_manager: true,
        is_subadmin: false,
        password: showPassword,
        is_superuser: false,
        is_active: true,
        is_staff: true,
        is_confirmed: true,
        building_list: [showBatimentSelected.value]
      }),
    };
    fetch(
        DNS +
        '/api/auth/createUser/',
        requestOptionsPost,
    ).then(async (response) => {
      const data = await response.json();
      console.log(data);
      if (data.status == 'OK') {
        console.log(data.fields);
        getUserList();
        setList({
          data: data.fields,
          error: false,
        });
        resetValues();
      } else {
        console.log('FAILED REQUEST');
        return;
      }
    });
  };

  const editUser = () => {
    let tmp = []

    // showUserBatiment.map((batiment))
    // console.log(tmp)
    const requestOptionsPatch = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + token['tokenRedux'],
      },
      body: JSON.stringify({
        pk: showApPk,
        username: showFname + ' ' + showLname,
        first_name: showFname,
        last_name: showLname,
        phone: showTel,
        email: showEmail,
        is_manager: true,
        is_subadmin: false,
        is_superuser: false,
        is_active: true,
        is_staff: true,
        is_confirmed: true,
        building_list: showUserBatiment.map((batiment) => batiment.id)
      }),
    };

    if (showPassword) {
      requestOptionsPatch['password'] = showPassword;
    }
    fetch(
        DNS +
        '/api/auth/deleteGetPatchUser/',
        requestOptionsPatch,
    ).then(async (response) => {
      const data = await response.json();
      console.log(data);
      if (data.status == 'OK') {
        console.log(data.fields);
        getUserList();
        setList({
          data: data.fields,
          error: false,
        });
        resetValues();
      } else {
        console.log('FAILED REQUEST');
        return;
      }
    });
  };

  const handleSubmit = (event) => {
    setValidated(true);
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      'borderColor': state.isFocused ?
        '#ddd' : validated && showEntrepriseSelected.length == 0 ?
        'red' : '#ddd',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ?
          '#ddd' : validated && showEntrepriseSelected.length == 0 ?
          'red' : '#ddd',
      },
    }),
  };

  let content = null;

  if (alist.error) {
    content = <p>ERROR: couldn't load appointment list properly.</p>;
  }

  const listFilter = [];

  if (alist.data) {
    console.log(alist)
    content = alist.data
        .slice(0)
        .reverse()
        .filter((val) => {
          if (
            val.email != ''
          ) {
            if (searchTerm == '') {
              listFilter.push(val);
              return val;
            } else if (
              val.email.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              listFilter.push(val);
              return val;
            } else if (
              val.phone.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              listFilter.push(val);
              return val;
            } else if (
              val.first_name.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              listFilter.push(val);
              return val;
            } else if (
              val.last_name.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              listFilter.push(val);
              return val;
            }
          }
        })
        .map((users, key) => (
          <tr id="pk" value={users.pk} key={key}>
            <td>{users.email}</td>
            <td>{users.first_name}</td>
            <td>{users.last_name}</td>
            <td>{users.phone}</td>
            <td>{users.is_manager == true ? 'Manager' :
              users.is_subadmin == true ? 'Sous-Admin' :
              users.is_superuser == true ? 'Administrateur' :
              'Inconnu'
            }</td>
            <td>{users.entreprise != null ? users.entreprise.nameEntreprise : null}</td>
            <td className="td-actions text-right">
              <a
                className="btn-sm btn-round ml-1 mr-1  btn-primary"
                style={{cursor: 'pointer'}}
                onClick={() => {
                  setShowModal(true);
                  setApPk(users.pk);
                  setEmail(users.email);
                  setUsername(users.username);
                  setTel(users.phone)
                  setLname(users.last_name);
                  setFname(users.first_name);
                  setUserBatiment(users.building_list);
                  console.log("user data: ", users.building_list[0].id)
                  
                }}
              >
                <i className="fas fa-edit"></i>
              </a>
              <a
                className="btn-sm btn-round btn-danger ml-1"
                style={{cursor: 'pointer'}}
                onClick={() => {
                  if (
                    window.confirm(
                        'Souhaitez vous vraiment supprimer cette Utilisateur ?',
                    )
                  ) {
                    deleteUser(users.pk);
                  }
                }}
              >
                <i className="fas fa-times"></i>
              </a>
            </td>
          </tr>
        ));
  }

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Liste des comptes</Card.Title>
              <p className="card-category">
                Saisissez sur cette interface le compte recherché
              </p>
              <div className="row" style={{paddingTop: '15px'}}>
                <Button
                  id="Ajouter"
                  className={'btn-round mr-1'}
                  onClick={() => {
                    setShowModal2(true);
                    resetValues();
                    // setDate(new Date());
                  }}
                >
                  Ajouter un nouveau compte
                </Button>{' '}
                <div id="custom-search-input">
                  <div className="input-group col-md-12">
                    <input
                      type="text"
                      className="form-control ml-5"
                      placeholder="Rechercher"
                      onChange={(event) => {
                        setSearchTerm(event.target.value);
                      }}
                    />
                    <span className="input-group-btn btn btn-primary">
                      <span className=" fas fa-search"></span>
                    </span>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive">
              <Table className="table-hover table-striped">
                <thead>
                  <tr>
                    <th className="border-0">Email</th>
                    <th className="border-0">Nom</th>
                    <th className="border-0">Prénom</th>
                    <th className="border-0">Numéro de Téléphone</th>
                    <th className="border-0">Rôle</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>


{/* CRÉER  LE COMPTE MODAL BELOW */}
                <Modal
                  className="modal modal-primary"
                  onSubmit={() => addUser()}
                  show={showModal2}
                  onHide={() => {setShowModal2(false); setPassword('');}}
                >
                  <Modal.Header className="justify-content-center">
                    <div className="modal-profile">
                      <i className="nc-icon fa fa-edit"></i>
                    </div>
                  </Modal.Header>

                  <div className="modal-body">
                    <p className="text-center">Créer un compte? </p>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                      <Form>
                        <Row >
                          <Col>
                            {/* EMAIL FIELD  */}
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label >Adresse Email:</Form.Label>
                              <Form.Control
                                required
                                type="email"
                                placeholder="Entrez un email"
                                value={showEmail}
                                onChange={(e) => {
                                  setEmail(e.currentTarget.value);
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                  Entrez un email valide
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>

                      <Form>
                        <Row >
                          <Col>
                            {/* LASTNAME FIELD  */}
                            <Form.Group controlId="formBasicLastname">
                              <Form.Label>Nom:</Form.Label>
                              <Form.Control
                                required
                                type="Lastname"
                                placeholder="Entrez un nom"
                                value={showLname}
                                onChange={(e) => {
                                  setLname(e.currentTarget.value);
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                  Entrez un nom valide
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col>
                            {/* FIRSTNAME FIELD  */}
                            <Form.Group controlId="formBasicFirstname">
                              <Form.Label>Prénom:</Form.Label>
                              <Form.Control
                                required
                                type="firstname"
                                placeholder="Entrez un prénom"
                                value={showFname}
                                onChange={(e) => {
                                  setFname(e.currentTarget.value);
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                  Entrez un prénom valide
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>

                      <Form>
                        <Row >
                          <Col>
                            {/* PASSWORD FIELD  */}
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Mot de passe:</Form.Label>
                              <Form.Control
                                required
                                type="password"
                                placeholder="Entrez un mot de passe"
                                value={showPassword}
                                onChange={(e) => {
                                  setPassword(e.currentTarget.value);
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                  Entrez un mot de passe valide
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col>
                            {/* ORGANIZATION  */}
                            <Form.Group controlId="formBasicOrganization">
                              <Form.Label>Numéro de Téléphone:</Form.Label>
                              <Form.Control
                                required
                                type="Lastname"
                                placeholder="Entrez un numéro"
                                value={showTel}
                                onChange={(e) => {
                                  setTel(e.currentTarget.value);
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                  Entrez un nom valide
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>

                      <Form>
                        <Row>
                          <Col>
                            {/* ROLE  */}
                            <Form.Group controlId="formBasicOrganization">
                              <Form.Label style={{marginLeft: '43%'}}>Batiment:</Form.Label>
                              <Select
                                styles={customStyles}
                                isClearable={true}
                                isSearchable={true}
                                components={animatedComponents}
                                value={showBatimentSelected}
                                options= {showBatiment}
                                onChange={(e) =>
                                  setBatimentSelected(
                                    e == null ?
                                    [] :
                                    e,
                                  )
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                  Choisir un Batiment valide
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                      <br/>
                      <Button className={'btn-round btn-wd'} type="submit">
                          Créer le compte
                      </Button>
                      <div className="clearfix"></div>
                    </Form>
                  </div>
                </Modal>

{/* MODIFIER LE COMPTE MODAL BELOW */}
                <Modal
                  className="modal modal-primary"
                  onSubmit={() => editUser()}
                  show={showModal}
                  onHide={() => {setShowModal(false); setPassword('');}}
                >
                  <Modal.Header className="justify-content-center">
                    <div className="modal-profile">
                      <i className="nc-icon fa fa-edit"></i>
                    </div>
                  </Modal.Header>

                  <div className="modal-body">
                    <p className="text-center">Modifier le compte? </p>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                      <Form>
                        <Row >
                          <Col>
                            {/* EMAIL FIELD  */}
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label >Adresse Email:</Form.Label>
                              <Form.Control
                                required
                                type="email"
                                placeholder="Entrez un email"
                                value={showEmail}
                                onChange={(e) => {
                                  setEmail(e.currentTarget.value);
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                  Entrez un email valide
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>

                      <Form>
                        <Row >
                          <Col>
                            {/* LASTNAME FIELD  */}
                            <Form.Group controlId="formBasicLastname">
                              <Form.Label>Nom:</Form.Label>
                              <Form.Control
                                required
                                type="Lastname"
                                placeholder="Entrez un nom"
                                value={showLname}
                                onChange={(e) => {
                                  setLname(e.currentTarget.value);
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                  Entrez un nom valide
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col>
                            {/* FIRSTNAME FIELD  */}
                            <Form.Group controlId="formBasicFirstname">
                              <Form.Label>Prénom:</Form.Label>
                              <Form.Control
                                required
                                type="firstname"
                                placeholder="Entrez un prénom"
                                value={showFname}
                                onChange={(e) => {
                                  setFname(e.currentTarget.value);
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                  Entrez un prénom valide
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>

                      <Form>
                        <Row >
                          <Col>
                            {/* PASSWORD FIELD  */}
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Mot de passe:</Form.Label>
                              <Form.Control
                                required
                                type="password"
                                placeholder="Entrez un mot de passe"
                                value={showPassword}
                                onChange={(e) => {
                                  setPassword(e.currentTarget.value);
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                  Entrez un mot de passe valide
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col>
                            {/* ORGANIZATION  */}
                            <Form.Group controlId="formBasicOrganization">
                              <Form.Label>Numéro de Téléphone:</Form.Label>
                              <Form.Control
                                required
                                type="Lastname"
                                placeholder="Entrez un numéro"
                                value={showTel}
                                onChange={(e) => {
                                  setTel(e.currentTarget.value);
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                  Entrez un nom valide
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>

                      <Form>
                      </Form>
                      <br/>
                      <Button className={'btn-round btn-wd'} type="submit">
                          Modifier le compte
                      </Button>
                      <div className="clearfix"></div>
                    </Form>
                  </div>
                </Modal>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserList;
