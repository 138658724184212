import 'assets/css/HAGD-Custom.css';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import axios from 'axios';
import AsyncCSV from 'components/csvExport';
import AsyncExcel from 'components/excelExport';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {fr} from 'react-date-range/src/locale/index';
import DNS from '../components/DNS/dns.js';
import Moment from 'react-moment';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import TimePickerComponent from 'components/DateTime/TimePicker';
import DatePickerComponent from 'components/DateTime/DatePicker';

// react-bootstrap components
import {
  Button,
  Card,
  Modal,
  Form,
  Table,
  Container,
  Row,
  Col,
  Dropdown,
} from 'react-bootstrap';
import {DateRange} from 'react-date-range';
import { type } from 'jquery';

const TableList = () => {
  const token = useSelector((state) => state.token);
  const currentBuilding = useSelector((state) => state.currentBuilding.currentbuildingRedux);
  const user = useSelector((state) => state.user.user);
  const animatedComponents = makeAnimated();

  const [showModal, setShowModal] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);
  const [showModalComments, setShowModalComments] = React.useState(false);
  const [showPrenom, setprenom] = useState('');
  const [showComments, setComments] = useState('');
  const [showHour, setHour] = useState('');
  const [showDate, setDate] = useState('');
  const [showName, setName] = useState('');
  const [showEmail, setEmail] = useState('');
  const [showApPk, setApPk] = useState('');
  const [searchTerm, setSearchTerm] = useState('');


  const [showBatiment, setBatiment] = useState([]);
  const [showPersonHour, setPersonHour] = useState([]);
  const [showBatimentSelected, setBatimentSelected] = useState([]);

  const [showDisplayDateRange, setDisplayDateRange] = useState('none');
  const [showTodayDate, setTodayDate] = useState('');
  const [showMaxdayDate, setMaxdayDate] = useState('');
  const [sortState, setSortState] = useState('neutral')
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [alist, setList] = useState([]);
  const [ogList, setOgList] = useState([])
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token['tokenRedux'],
    },
  };
  useEffect(() => {
    // getBatiment();
    getTodayDate();
    getReqBuilding()
    console.log("1253554", user, currentBuilding)
  }, []);

  useEffect(() => {
    let tmp = ogList
    console.log(ogList)

    if (sortState == 'neutral') {
      setList(ogList)
    }

    if (sortState == 'ReversefirstName') {
      let toSort = tmp.slice(0).sort((a, b) =>
        a.first_name.localeCompare(b.first_name)
      );
      setList(toSort)
    }

    if (sortState == 'firstName') {
      let toSort = tmp.slice(0).sort((a, b) =>
        b.first_name.localeCompare(a.first_name)
      );
      setList(toSort)
    }

    if (sortState == 'ReverselastName') {
      let toSort = tmp.slice(0).sort((a, b) =>
        a.last_name.localeCompare(b.last_name)
      );
      setList(toSort)
    }

    if (sortState == 'lastName') {
      let toSort = tmp.slice(0).sort((a, b) =>
        b.last_name.localeCompare(a.last_name)
      );
      setList(toSort)
    }

    if (sortState == 'Reversebuilding') {
      let toSort = tmp.slice(0).sort((a, b) => {
        // Vérifier si 'a' et 'b' ont la propriété 'building'
        const hasBuildingA = a.hasOwnProperty('building');
        const hasBuildingB = b.hasOwnProperty('building');
    
        // Si 'a' n'a pas 'building' et 'b' l'a, 'a' doit être avant 'b'
        if (!hasBuildingA && hasBuildingB) return -1;
        // Si 'a' a 'building' et 'b' ne l'a pas, 'b' doit être avant 'a'
        if (hasBuildingA && !hasBuildingB) return 1;
        // Si les deux ont ou n'ont pas 'building', comparer les valeurs
        if (hasBuildingA && hasBuildingB) {
          return a.building.localeCompare(b.building);
        }
        // Si ni 'a' ni 'b' n'ont 'building', ils sont considérés égaux
        return 0;
      });
      setList(toSort);
    }

    if (sortState === 'building') {
      let toSort = tmp.slice(0).sort((a, b) => {
        // Vérifier si 'a' et 'b' ont la propriété 'building'
        const hasBuildingA = a.hasOwnProperty('building');
        const hasBuildingB = b.hasOwnProperty('building');
    
        // Si 'a' n'a pas 'building' et 'b' l'a, 'a' doit être avant 'b'
        if (!hasBuildingA && hasBuildingB) return -1;
        // Si 'a' a 'building' et 'b' ne l'a pas, 'b' doit être avant 'a'
        if (hasBuildingA && !hasBuildingB) return 1;
        // Si les deux ont ou n'ont pas 'building', comparer les valeurs
        if (hasBuildingA && hasBuildingB) {
          return b.building.localeCompare(a.building);
        }
        // Si ni 'a' ni 'b' n'ont 'building', ils sont considérés égaux
        return 0;
      });
      setList(toSort);
    }

    if (sortState === 'Reverseschedule') {
      let toSort = tmp.slice(0).sort((a, b) => {
        let dateA = a?.hour ? formatTimeRange(a.hour) : -Infinity;
        let dateB = b?.hour ? formatTimeRange(b.hour) : -Infinity;
        return dateA - dateB;
      });
      console.log(toSort);
      setList(toSort);
    }
    
    if (sortState === 'schedule') {
      let toSort = tmp.slice(0).sort((a, b) => {
        let dateA = a?.hour ? formatTimeRange(a.hour) : Infinity;
        let dateB = b?.hour ? formatTimeRange(b.hour) : Infinity;
        return dateB - dateA;
      });
      console.log(toSort);
      setList(toSort);
    }
    

    if (sortState == 'Reversebegin') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        let timeA = a?.begin_time;
        let timeB = b?.begin_time;
  
        return timeA?.localeCompare(timeB);
      })
      setList(toSort)
    }
    if (sortState == 'begin') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        let timeA = a?.begin_time;
        let timeB = b?.begin_time;
  
        return timeB?.localeCompare(timeA);
        })
      setList(toSort)
    }

    if (sortState == 'Reverseend') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        let timeA = a?.end_time;
        let timeB = b?.end_time;
  
        return timeA?.localeCompare(timeB);
      })
      setList(toSort)
    }
    if (sortState == 'end') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        let timeA = a?.end_time;
        let timeB = b?.end_time;
  
        return timeB?.localeCompare(timeA);
        })
      setList(toSort)
    }

    if (sortState == 'Reversecode') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        let codeA = a?.confirm_code;
        let codeB = b?.confirm_code;
  
        return codeA - codeB;
      })
      setList(toSort)
    }
    if (sortState == 'code') {
      let toSort = tmp.slice(0).sort((a, b) => {
        console.log(typeof(a?.confirm_code), typeof(b?.confirm_code))
        let codeA = a?.confirm_code;
        let codeB = b?.confirm_code;
  
        return codeB - codeA;
        })
      setList(toSort)
    }
  }, [sortState])

  const handleSortState = (sortingOrder) => {
    if (sortingOrder == 'firstName' ) {
      if (sortState == 'firstName') {
        setSortState('ReversefirstName')
      } else {
        setSortState('firstName')
      }
    }

    if (sortingOrder == 'lastName' ) {
      if (sortState == 'lastName') {
        setSortState('ReverselastName')
      } else {
        setSortState('lastName')
      }
    }

    if (sortingOrder == 'building' ) {
      if (sortState == 'building') {
        setSortState('Reversebuilding')
      } else {
        setSortState('building')
      }
    }

    if (sortingOrder == 'schedule' ) {
      if (sortState == 'schedule') {
        setSortState('Reverseschedule')
      } else {
        setSortState('schedule')
      }
    }

    if (sortingOrder == 'begin' ) {
      if (sortState == 'begin') {
        setSortState('Reversebegin')
      } else {
        setSortState('begin')
      }
    }
    
    if (sortingOrder == 'end' ) {
      if (sortState == 'end') {
        setSortState('Reverseend')
      } else {
        setSortState('end')
      }
    }

    if (sortingOrder == 'code' ) {
      if (sortState == 'code') {
        setSortState('Reversecode')
      } else {
        setSortState('code')
      }
    }

  }

  const requestOptionsAxiosGet = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token['tokenRedux'],
    },
  };


  const formatTimeRange = (timeRange) => {
    const [startTimeStr] = timeRange?.split(' - ');
    const [hours, minutes, seconds] = startTimeStr?.split(':');
    return new Date(0, 0, 0, hours, minutes, seconds);
  }


  const resetValues = () => {
    setValidated(false);
    setName('');
    setHour('');
    setDate('');
    setprenom('');
    setEmail('');
    setComments('');
    setApPk('');
  };

  const getReqBuilding = async () => {
    let tmp = [];
    let req = await axios
    .get(
        DNS +
      '/api/building/',
        requestOptionsAxiosGet,
    )
    // .then((response) => {
      console.log(currentBuilding)
      if (user.role == 'is_manager') {
        req.data.map((elem) => {
          if (currentBuilding.name == elem.name) {
            tmp = tmp.concat({'value': elem.id, 'label': elem.name});
          }
        });
      } else {
        req.data.map((elem) => {
          tmp = tmp.concat({'value': elem.id, 'label': elem.name});
        });
      }
      getReqHour(tmp)
    // })
  }

  const getReqHour = async (building) => {
    let tmp3 = [];
    if (user.role == 'is_manager') {
      let req = await axios.get(
        DNS +
        '/api/hour/' + currentBuilding.pk,
        requestOptionsAxiosGet,
      )    
    // .then((response) => {
      console.log('req:   ', req.data)
      req.data?.fields?.map((elem) => {
        console.log("elem: ",elem)
        tmp3 = tmp3.concat({'value': elem, 'label': elem.begin + ' - ' + elem.begin_max});
      });
      getRelatedEmployees(building, tmp3)
    } else {
    let req = await axios.get(
      DNS +
      '/api/hour/',
      requestOptionsAxiosGet,
    )    
  // .then((response) => {
    req.data.map((elem) => {
      console.log("elem: ",elem)
      tmp3 = tmp3.concat({'value': elem, 'label': elem.begin + ' - ' + elem.begin_max});
    });
  // });
      getEmployees(building, tmp3)
    }


  }

  const getRelatedEmployees = async (tmp, tmp3) => {
    try {
        // Fetch the list of people
        const response = await axios.get(DNS + '/api/person/', requestOptionsAxiosGet);
        let people = response.data;

        // Create a map for faster lookup
        const buildingsMap = new Map(tmp.map(building => [building.value, building.label]));
        const hoursMap = new Map(tmp3.map(hour => [hour.value?.pkPerson?.id, hour.label]));


        // Filter and map data in a single loop for efficiency
        let finalList = people.map(person => {
                // Get the correct building label if the person is assigned to one
                let buildingLabel = null;
                let hourLabel = null;

                tmp3.forEach(hour => {
                    if (hour.value.pkPerson?.id === person.id) {
                        hourLabel = hour.label;
                        if (buildingsMap.has(hour.value.pkBuilding?.id)) {
                            buildingLabel = buildingsMap.get(hour.value.pkBuilding?.id);
                        }
                    }
                });

                // Assign labels
                if (buildingLabel) person.building = buildingLabel;
                if (hourLabel) person.hour = hourLabel;

                return person;
            })
            .filter(person => person.building === currentBuilding.name && person.building);

        // Update state after processing
        setBatiment(tmp);
        setPersonHour(tmp3);
        console.log("Filtered People:", finalList);
        setList(finalList);
        setOgList(finalList);
    } catch (error) {
        console.error("Error fetching employees:", error);
    }
};



  const getEmployees = (tmp, tmp3) => {
    axios.get(
        DNS +
        '/api/person/' ,
        requestOptionsAxiosGet,
    ).then(async (response) => {
        tmp.filter((building) => {
          tmp3.filter((hour) => {
          response.data.map((person) => {
            if (building.value == hour.value?.pkBuilding?.id && hour.value.pkPerson?.id == person.id) {
              person.building = building.label
          }
          if (hour.value?.pkPerson?.id == person.id) {
            person.hour = hour.label
          }
        }
          )
        })
        })
        // console.log("TEST:  ", tmp3)
        setBatiment(tmp);
        setPersonHour(tmp3)
        // console.log("rep   : ", response.data)
        setList(
          response.data
        );
        setOgList(response.data)
        return;
    });
  };

  const deletePerson = (personpk) => {
    // console.log(token['tokenRedux']);
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + token['tokenRedux'],
      },
      body: JSON.stringify({pk: personpk}),
    };
    fetch(
        DNS +
        '/api/person/' +
        personpk,
        requestOptions,
    ).then(async (response) => {
        getReqBuilding();
    });
  };

  const editPerson = () => {
    // console.log(showApPk)
    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + token['tokenRedux'],
      },
      body: JSON.stringify({
        pkBuilding: showBatimentSelected.value,
        first_name: showPrenom,
        last_name: showName,
      }),
    };
    fetch(
        DNS +
        '/api/person/' + showApPk,
        requestOptions,
    ).then(async (response) => {
      const data = await response.json();
      if (data.status == 'WRONG') {
        alert('Impossible de saisir votre arrivée, assurez-vous que vous avez bien remplis les champs.');
      }
      getReqBuilding();
      setShowModal2(false);
      resetValues();

    });
  };

  const getTodayDate = () => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();


    today = yyyy + '-' + mm + '-' + dd;
    const maxday = (yyyy + 1) + '-' + mm + '-' + dd;

    setTodayDate(today);
    setMaxdayDate(maxday);
  };

  const handleSubmit = (event) => {
    createPerson()
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      'borderColor': state.isFocused ?
        '#ddd' : validated && showBatimentSelected.length == 0 ?
        'red' : '#ddd',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ?
          '#ddd' : validated && showBatimentSelected.length == 0 ?
          'red' : '#ddd',
      },
    }),
  };

  
  const createPerson = (e) => {
    e?.preventDefault();

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + token['tokenRedux'],
      },
      body: {
        first_name: showPrenom,
        last_name: showName,
        begin_time: '00:00:00',
        end_time: '00:00:00',
      },
    };
    requestOptions.body = JSON.stringify(requestOptions.body);
    fetch(
        DNS +
        '/api/person/',
        requestOptions,
    )
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 'WRONG') {
            alert('Impossible de saisir votre arrivée, assurez-vous que vous avez bien remplis les champs.');
          }
          getReqBuilding();
          setShowModal(false);
        });
  };

  let content = null;


  const listFilter = [];
  const headers = [
    {label: 'Prénom', key: 'first_name'},
    {label: 'Nom', key: 'last_name'},
    {label: 'Batiment', key: 'building'},
    {label: 'Plage horaire', key: 'hour'},
    {label: 'Arrivée ce matin', key: 'begin_time'},
    {label: 'Fin de journée', key: 'end_time'},
    {label: 'Code', key: 'confirm_code'},
  ];

  if (alist.length > 0) {
    content = alist
        .slice(0)
        .reverse()
        .filter((val) => {
              if (searchTerm == '') {
                listFilter.push(val);
                return val;
              } else if (
                val.first_name.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                listFilter.push(val);
                return val;
              }  else if (
                val.last_name.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                listFilter.push(val);
                return val;
              } 
               else if (val.building && val.hour) {
               if (val.building.toLowerCase().includes(searchTerm.toLowerCase())) {
                listFilter.push(val);
                return val;
               }
               if (val.hour.includes(searchTerm)) {
                listFilter.push(val);
                return val;
               }
              }
  
        })
        .map((person, key) => (
          <tr id="pk" value={person.id} key={key}>
            <td>{person.first_name}</td>
            <td>{person.last_name}</td>
            <td>{person.building}</td>
            <td>{person?.hour}</td>
            <td>{person.begin_time  == '00:00:00' ? '' : person.begin_time}</td>
            <td>{person.end_time == '00:00:00' ? '' : person.end_time}</td>
            <td>{person.confirm_code}</td>
            {user.role == 'is_superuser' || user.role == 'is_subadmin' ?
            <td className="td-actions text-right">
              <a
                className="btn-sm btn-round ml-1 mr-1  btn-warning"
                style={{cursor: 'pointer'}}
                onClick={() => {
                  setShowModal2(true);
                  setprenom(person.first_name);
                  setName(person.last_name);
                  setApPk(person.id);
                }}
              >
                <i className="fas fa-edit"></i>
              </a>
              <a
                className="btn-sm btn-round btn-danger ml-1"
                style={{cursor: 'pointer'}}
                onClick={() => {
                  if (
                    window.confirm(
                        'Souhaitez vous vraiment supprimer cette Personne ?',
                    )
                  ) {
                    deletePerson(person.id);
                  }
                }}
              >
                <i className="fas fa-times"></i>
              </a>
            </td>: null }
          </tr>
        ));
  }

  const checkTime = () => {
    if (typeof(showHour) == typeof('string')) {
      return (new Date('June 04, 2022 ' + showHour));
    } else {
      return (showHour);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Liste des présence</Card.Title>
              <p className="card-category">
                Consulter les personnes présentes
              </p>
              <div className="row" style={{paddingTop: '15px'}}>

                <Button
                  id="Ajouter"
                  className={'btn-round mr-1'}
                  onClick={() => {
                    setShowModal(true);
                    resetValues();
                    setDate(new Date());
                  }}
                >
                  Ajouter une nouvelle personne
                </Button>
                <AsyncExcel
                  toExport={listFilter}
                  headers={headers}
                  sentence="Extraire les données"
                />
                <div id="custom-search-input">
                  <div className="input-group col-md-12">
                    <input
                      type="text"
                      className="form-control ml-5"
                      placeholder="Rechercher"
                      onChange={(event) => {
                        setSearchTerm(event.target.value);
                      }}
                    />
                    <span className="input-group-btn btn btn-primary">
                      <span className=" fas fa-search"></span>
                    </span>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive">
              <Table className="table-hover table-striped">
                <thead>
                  <tr>
                    <th onClick={() => handleSortState('firstName')} style={{color: sortState == 'ReversefirstName' ? "#ff0002" : sortState == 'firstName' ? "#5db5c7" : "#ffc106"}} className="border-0">Prénom {sortState == 'ReversefirstName' ? <i className="fas fa-arrow-down"></i> : sortState == 'firstName' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>} </th>
                    <th onClick={() => handleSortState('lastName')} style={{color: sortState == 'ReverselastName' ? "#ff0002" : sortState == 'lastName' ? "#5db5c7" : "#ffc106"}} className="border-0">Nom {sortState == 'ReverselastName' ? <i className="fas fa-arrow-down"></i> : sortState == 'lastName' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    <th onClick={() => handleSortState('building')} style={{color: sortState == 'Reversebuilding' ? "#ff0002" : sortState == 'building' ? "#5db5c7" : "#ffc106"}} className="border-0">Batiment {sortState == 'Reversebuilding' ? <i className="fas fa-arrow-down"></i> : sortState == 'building' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    <th onClick={() => handleSortState('schedule')} style={{color: sortState == 'Reverseschedule' ? "#ff0002" : sortState == 'schedule' ? "#5db5c7" : "#ffc106"}} className="border-0">Plage horaire {sortState == 'Reverseschedule' ? <i className="fas fa-arrow-down"></i> : sortState == 'schedule' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    <th onClick={() => handleSortState('begin')} style={{color: sortState == 'Reversebegin' ? "#ff0002" : sortState == 'begin' ? "#5db5c7" : "#ffc106"}} className="border-0">Arrivée ce matin {sortState == 'Reversebegin' ? <i className="fas fa-arrow-down"></i> : sortState == 'begin' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    <th onClick={() => handleSortState('end')} style={{color: sortState == 'Reverseend' ? "#ff0002" : sortState == 'end' ? "#5db5c7" : "#ffc106"}} className="border-0">Fin de journée {sortState == 'Reverseend' ? <i className="fas fa-arrow-down"></i> : sortState == 'end' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    <th onClick={() => handleSortState('code')} style={{color: sortState == 'Reversecode' ? "#ff0002" : sortState == 'code' ? "#5db5c7" : "#ffc106"}} className="border-0">Code {sortState == 'Reversecode' ? <i className="fas fa-arrow-down"></i> : sortState == 'code' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    {sortState != 'neutral' ?
                    <div style={{marginLeft: "75%"}}>
                      <a
                      className="btn-sm btn-round ml-1 mr-1  btn-warning"
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        setSortState('neutral')
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </a>
                    </div>:
                      null
                    }
                  </tr>
                </thead>
                <tbody>{content}</tbody>
                <Modal
                  className="modal modal-primary"
                  onSubmit={() => editPerson()}
                  show={showModal2}
                  onHide={() => setShowModal2(false)}
                  autoFocus={false}
                  enforceFocus={false}
                >
                  <Modal.Header className="justify-content-center">
                    <div className="modal-profile">
                      <i className="nc-icon fa fa-edit"></i>
                    </div>
                  </Modal.Header>

                  <div className="modal-body">
                    <p className="text-center">Modifer la personne?</p>
                    <Form noValidate validated={validated}>
                      <Form.Group as={Row} controlId="formHorizontalPrénom">
                      <Form.Label column sm={4}>
                        Prénom:
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          required
                          type="Prénom"
                          placeholder="Prénom"
                          value={showPrenom}
                          onChange={(e) => setprenom(e.currentTarget.value)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formHorizontalNom">
                      <Form.Label column sm={4}>
                        Nom:
                      </Form.Label>
                      <Col sm={8}>
                      <Form.Control
                          required
                          type="Nom"
                          placeholder="Nom"
                          value={showName}
                          onChange={(e) => setName(e.currentTarget.value)}
                        />
                      </Col>
                    </Form.Group>
                      <Form.Group as={Row}>
                        <Button
                          className="btn-simple btn-round col-sm-6"
                          type="button"
                          variant="link"
                          onClick={() => setShowModal2(false)}
                        >
                          Fermer
                        </Button>
                        <Button className="btn-round col-sm-6" type="submit">
                          Modifier{' '}
                        </Button>{' '}
                      </Form.Group>
                    </Form>
                  </div>
                </Modal>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal
        className="modal modal-primary"
        show={showModal}
        onHide={() => setShowModal(false)}
        onSubmit={(e) => createPerson(e)}
        autoFocus={false}
        enforceFocus={false}
      >
        <Modal.Header className="justify-content-center">
          <div className="modal-profile">
            <i className="nc-icon nc-simple-add"></i>
          </div>
        </Modal.Header>
        <div className="modal-body">
          <p className="text-center">Ajouter un nouveau collaborateur ?</p>
          <Form noValidate validated={validated} >
            <Form.Group as={Row} controlId="formHorizontalPrénom">
              <Form.Label column sm={4}>
                Prénom:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  required
                  type="Prénom"
                  placeholder="Prénom"
                  value={showPrenom}
                  onChange={(e) => setprenom(e.currentTarget.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalNom">
              <Form.Label column sm={4}>
                Nom:
              </Form.Label>
              <Col sm={8}>
              <Form.Control
                  required
                  type="Nom"
                  placeholder="Nom"
                  value={showName}
                  onChange={(e) => setName(e.currentTarget.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Button
                className="btn-simple col-sm-6"
                type="button"
                variant="link"
                onClick={() => setShowModal(false)}
              >
                Fermer
              </Button>
              <Button className="btn-fill btn-round col-sm-6" type="submit">
                CRÉER{' '}
              </Button>{' '}
            </Form.Group>
          </Form>
        </div>
      </Modal>
    </Container>
  );
};

export default TableList;
