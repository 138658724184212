import 'assets/css/HAGD-Custom.css';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import axios from 'axios';
import AsyncCSV from 'components/csvExport';
import AsyncExcel from 'components/excelExport';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {fr} from 'react-date-range/src/locale/index';
import DNS from '../components/DNS/dns.js';
import Moment from 'react-moment';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import TimePickerComponent from 'components/DateTime/TimePicker';
import DatePickerComponent from 'components/DateTime/DatePicker';
import {DateRange} from 'react-date-range';

// react-bootstrap components
import {
  Button,
  Card,
  Modal,
  Form,
  Table,
  Container,
  Row,
  Col,
  Dropdown,
} from 'react-bootstrap';
import {Calendar} from 'react-date-range';

const MyHistoryList = () => {
  const token = useSelector((state) => state.token);
  const currentBuilding = useSelector((state) => state.currentBuilding.currentbuildingRedux);
  const user = useSelector((state) => state.user.user);


  const [showModal, setShowModal] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);
  const [showPrenom, setprenom] = useState('');
  const [showComments, setComments] = useState('');
  const [showHour, setHour] = useState('');
  const [showDate, setDate] = useState('');
  const [showName, setName] = useState('');
  const [showEmail, setEmail] = useState('');
  const [showApPk, setApPk] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showDisplayDateRange, setDisplayDateRange] = useState('none');

  const [showBatiment, setBatiment] = useState([]);
  const [buildingList, setBuildingList] = useState([])
  const [showPersonHour, setPersonHour] = useState([]);
  const [showBatimentSelected, setBatimentSelected] = useState([]);
  const [sortState, setSortState] = useState('neutral')
  const [showTodayDate, setTodayDate] = useState('');
  const [showMaxdayDate, setMaxdayDate] = useState('');
  const [validated, setValidated] = useState(false);
  const [dateValue, setDateValue] = useState(new Date());
  const [dateSelector, setDateSelector] = useState(new Date());
  // const [dateToSend, setDateToSend] = useState("");
  const [alist, setList] = useState([]);
  const [ogList, setOgList] = useState([])
  const [ogList2, setOgList2] = useState([])
  const animatedComponents = makeAnimated();
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  
  
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token['tokenRedux'],
    },
  };
  useEffect(() => {
    console.log(token)
    if (user.role == 'is_superuser'|| user.role == 'is_subadmin') {
      getBatiment();
    } else {
      getSingleBuilding()
    }

    let today = getTodayDate();
    setDateSelector(today);
    if (user.role == 'is_superuser'|| user.role == 'is_subadmin') {
      getHistoryDate();
    } else {
      getManagerHistoryDate();
    }
    console.log("1253555555555555554")
  }, []);

  useEffect(() => {
    let tmp = ogList
    console.log(ogList)

    if (sortState == 'neutral') {
      setList(ogList)
    }

    if (sortState == 'date') {
      let toSort = tmp.slice(0).sort((a, b) =>
        a.first_name.localeCompare(b.truedate)
      );
      setList(toSort)
    }
    
    if (sortState == 'ReverseDate') {
      let toSort = tmp.slice(0).sort((a, b) =>
        b.first_name.localeCompare(a.truedate)
      );
      setList(toSort)
    }

    if (sortState == 'ReversefirstName') {
      let toSort = tmp.slice(0).sort((a, b) =>
        a.first_name.localeCompare(b.first_name)
      );
      setList(toSort)
    }

    if (sortState == 'firstName') {
      let toSort = tmp.slice(0).sort((a, b) =>
        b.first_name.localeCompare(a.first_name)
      );
      setList(toSort)
    }

    if (sortState == 'ReverselastName') {
      let toSort = tmp.slice(0).sort((a, b) =>
        a.last_name.localeCompare(b.last_name)
      );
      setList(toSort)
    }

    if (sortState == 'lastName') {
      let toSort = tmp.slice(0).sort((a, b) =>
        b.last_name.localeCompare(a.last_name)
      );
      setList(toSort)
    }

    if (sortState == 'Reversebuilding') {
      let toSort = tmp.slice(0).sort((a, b) => {
        // Vérifier si 'a' et 'b' ont la propriété 'building'
        const hasBuildingA = a.hasOwnProperty('building');
        const hasBuildingB = b.hasOwnProperty('building');
    
        // Si 'a' n'a pas 'building' et 'b' l'a, 'a' doit être avant 'b'
        if (!hasBuildingA && hasBuildingB) return -1;
        // Si 'a' a 'building' et 'b' ne l'a pas, 'b' doit être avant 'a'
        if (hasBuildingA && !hasBuildingB) return 1;
        // Si les deux ont ou n'ont pas 'building', comparer les valeurs
        if (hasBuildingA && hasBuildingB) {
          return a.building.localeCompare(b.building);
        }
        // Si ni 'a' ni 'b' n'ont 'building', ils sont considérés égaux
        return 0;
      });
      setList(toSort);
    }

    if (sortState === 'building') {
      let toSort = tmp.slice(0).sort((a, b) => {
        // Vérifier si 'a' et 'b' ont la propriété 'building'
        const hasBuildingA = a.hasOwnProperty('building');
        const hasBuildingB = b.hasOwnProperty('building');
    
        // Si 'a' n'a pas 'building' et 'b' l'a, 'a' doit être avant 'b'
        if (!hasBuildingA && hasBuildingB) return -1;
        // Si 'a' a 'building' et 'b' ne l'a pas, 'b' doit être avant 'a'
        if (hasBuildingA && !hasBuildingB) return 1;
        // Si les deux ont ou n'ont pas 'building', comparer les valeurs
        if (hasBuildingA && hasBuildingB) {
          return b.building.localeCompare(a.building);
        }
        // Si ni 'a' ni 'b' n'ont 'building', ils sont considérés égaux
        return 0;
      });
      setList(toSort);
    }

    if (sortState === 'Reverseschedule') {
      let toSort = tmp.slice(0).sort((a, b) => {
        let dateA = a?.hour ? formatTimeRange(a.hour) : -Infinity;
        let dateB = b?.hour ? formatTimeRange(b.hour) : -Infinity;
        return dateA - dateB;
      });
      console.log(toSort);
      setList(toSort);
    }
    
    if (sortState === 'schedule') {
      let toSort = tmp.slice(0).sort((a, b) => {
        let dateA = a?.hour ? formatTimeRange(a.hour) : Infinity;
        let dateB = b?.hour ? formatTimeRange(b.hour) : Infinity;
        return dateB - dateA;
      });
      console.log(toSort);
      setList(toSort);
    }

    if (sortState == 'Reversebegin') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        let timeA = a?.begin_time;
        let timeB = b?.begin_time;
  
        return timeA?.localeCompare(timeB);
      })
      setList(toSort)
    }
    if (sortState == 'begin') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        let timeA = a?.begin_time;
        let timeB = b?.begin_time;
  
        return timeB?.localeCompare(timeA);
        })
      setList(toSort)
    }

    if (sortState == 'Reverseend') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        let timeA = a?.end_time;
        let timeB = b?.end_time;
  
        return timeA?.localeCompare(timeB);
      })
      setList(toSort)
    }
    if (sortState == 'end') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        let timeA = a?.end_time;
        let timeB = b?.end_time;
  
        return timeB?.localeCompare(timeA);
        })
      setList(toSort)
    }

    if (sortState == 'Reversecode') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        console.log(typeof(a?.confirm_code), typeof(b?.confirm_code))
        let codeA = a?.confirm_code;
        let codeB = b?.confirm_code;
  
        return codeA - codeB;
      })
      setList(toSort)
    }
    if (sortState == 'code') {
      let toSort = tmp.slice(0).sort((a, b) => {
        console.log(typeof(a?.confirm_code), typeof(b?.confirm_code))
        let codeA = a?.confirm_code;
        let codeB = b?.confirm_code;
  
        return codeB - codeA;
        })
      setList(toSort)
    }
  }, [sortState])

  const handleSortState = (sortingOrder) => {

    if (sortingOrder == 'date' ) {
      if (sortState == 'date') {
        setSortState('ReverseDate')
      } else {
        setSortState('date')
      }
    }

    if (sortingOrder == 'firstName' ) {
      if (sortState == 'firstName') {
        setSortState('ReversefirstName')
      } else {
        setSortState('firstName')
      }
    }

    if (sortingOrder == 'lastName' ) {
      if (sortState == 'lastName') {
        setSortState('ReverselastName')
      } else {
        setSortState('lastName')
      }
    }

    if (sortingOrder == 'building' ) {
      if (sortState == 'building') {
        setSortState('Reversebuilding')
      } else {
        setSortState('building')
      }
    }

    if (sortingOrder == 'schedule' ) {
      if (sortState == 'schedule') {
        setSortState('Reverseschedule')
      } else {
        setSortState('schedule')
      }
    }

    if (sortingOrder == 'begin' ) {
      if (sortState == 'begin') {
        setSortState('Reversebegin')
      } else {
        setSortState('begin')
      }
    }
    
    if (sortingOrder == 'end' ) {
      if (sortState == 'end') {
        setSortState('Reverseend')
      } else {
        setSortState('end')
      }
    }

    if (sortingOrder == 'code' ) {
      if (sortState == 'code') {
        setSortState('Reversecode')
      } else {
        setSortState('code')
      }
    }

  }


  const getBatiment = () => {
    axios
        .get(
            DNS +
          '/api/building/',
            requestOptionsAxiosGet,
        )
        .then((response) => {
          // console.log(response)
          let tmp = [];
          response.data.map((elem) => {
            console.log("listbuil: ", elem)
            tmp = tmp.concat({'value': elem.name, 'label': elem.name})
          });
          setBuildingList(tmp);
        });
  };

  const getSingleBuilding = () => {
    axios.get(
        DNS +
        '/api/building/' + toSlug(currentBuilding.name) ,
        requestOptionsAxiosGet,
    ).then(async (response) => {
          let tmp = [];
          tmp = tmp.concat({'value': response.data.name, 'label': response.data.name})
          console.log("single building: ", tmp)
          setBuildingList(tmp);
        });
  };

  function toSlug(modelName) {
    return modelName
        .toLowerCase()
        .replace(/[\s\-_]+/g, '-'); // Remplace espaces, underscores et tirets par un seul tiret '-'
  }

  const requestOptionsAxiosGet = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token['tokenRedux'],
    },
  };

  const formatTimeRange = (timeRange) => {
    const [startTimeStr] = timeRange?.split(' - ');
    const [hours, minutes, seconds] = startTimeStr?.split(':');
    return new Date(0, 0, 0, hours, minutes, seconds);
  }

  const filterList = (event, listeBatiment) => {
    console.log('batiment selected : ',showBatimentSelected)
    let tmp = []
    // event.preventDefault();
    setShowModal(false)

    if (showBatimentSelected != '') {
      alist.map((data) => {
        console.log( data?.building)
        if (data?.building == showBatimentSelected.label)  {
          tmp = tmp.concat(data)
        }
      })
      setList(tmp)
    } else {
      console.log("no building selected")
      setList(ogList)

    }

    
  }


  const ManagerFilterList = (allPersons) => {
    // console.log('batiment selected : ',showBatimentSelected)
    let tmp = []
    // event.preventDefault();

      allPersons.map((data) => {
        console.log( data?.building)
        if (data?.building == currentBuilding.name)  {
          tmp = tmp.concat(data)
        }
      })
      setList(tmp)
      setOgList(tmp)
  }

  const resetValues = () => {
    setValidated(false);
    setName('');
    setHour('');
    setDate('');
    setprenom('');
    setEmail('');
    setComments('');
    setApPk('');
  };


  const getManagerHistoryDate = (stateVar = state) => {
    let tmp = [];
    let tmp2 = [];
    let tmp3 = []

    const day = stateVar[0].startDate.getDate();
    const monthIndex = stateVar[0].startDate.getMonth();
    const year = stateVar[0].startDate.getFullYear();
    const myFormattedDate =
      year +
      '-' +
      (monthIndex + 1 >= 10 ? monthIndex + 1 : '0' + (monthIndex + 1)) +
      '-' +
      day;
    const day2 = stateVar[0].endDate.getDate();
    const monthIndex2 = stateVar[0].endDate.getMonth();
    const year2 = stateVar[0].endDate.getFullYear();
    const myFormattedDate2 =
      year2 +
      '-' +
      (monthIndex2 + 1 >= 10 ? monthIndex2 + 1 : '0' + (monthIndex2 + 1)) +
      '-' +
      day2;
    if (myFormattedDate == myFormattedDate2) {
      let req =  axios.get(
          DNS +
        '/api/history/?start=' + myFormattedDate,
          requestOptionsAxiosGet,
      )
      .then((response) => {
        let models = response.data[0]?.data
        let datadate = response.data[0]?.date
        console.log(datadate)
        models?.Building.map((elem) => {
          tmp = tmp.concat({'value': elem.id, 'label': elem.name});
        });
        models?.Hour.map((elem) => {
          tmp2 = tmp2.concat({'value': elem, 'label': elem.begin + ' - ' + elem.begin_max});
        });

        models?.Person.map((person) => {
          person.truedate = formatDateToFr(datadate)
          console.log("person:", person)
        })

        tmp.filter((building) => {
          tmp2.filter((hour) => {
          models?.Person.map((person) => {
            if (building.value == hour.value?.pkBuilding?.id && hour.value.pkPerson_id == person.id) {
              person.building = building.label
          }
          if (hour.value?.pkPerson_id == person.id) {
            person.hour = hour.label
          }
        }
          )
        })
        })
        setBatiment(tmp);
        setPersonHour(tmp2)
        console.log("models.person: ", models?.Person )
        if (models?.person ) {
          ManagerFilterList(models?.person)
        } else {
          setList("");
          setOgList("")
        }
        return;

      })
    } else {
      let req =  axios.get(
        DNS +
      '/api/history/?start=' + myFormattedDate +
      '&end=' +
      myFormattedDate2,
      requestOptionsAxiosGet,
    )
    .then((response) => {
      // Initialize temporary arrays
      let allPersons = [];
      let allBuildings = [];
      let allHours = [];
    
      // Iterate through the entire response.data array
      response.data.forEach((dataChunk) => {
        let datadate = dataChunk?.date
        let models = dataChunk?.data;
    
        // Process Building and Hour data
        let tmp = [];
        let tmp2 = [];
        models?.Building.forEach((elem) => {
          tmp = tmp.concat({ value: elem.id, label: elem.name });
        });
        models?.Hour.forEach((elem) => {
          tmp2 = tmp2.concat({ value: elem, label: `${elem.begin} - ${elem.begin_max}` });
        });
    
        // Process Person data and associate with Building and Hour
        let persons = models?.Person.map((person) => {
          person.truedate = formatDateToFr(datadate)
          tmp.forEach((building) => {
            tmp2.forEach((hour) => {
              if (building.value === hour.value?.pkBuilding?.id && hour.value.pkPerson_id === person.id) {
                person.building = building.label;
              }
              if (hour.value?.pkPerson_id === person.id) {
                person.hour = hour.label;
              }
            });
          });
          return person; // Return the modified person object
        });
    
        // Concatenate processed data to global arrays
        allPersons = allPersons.concat(persons || []);
        allBuildings = allBuildings.concat(tmp);
        allHours = allHours.concat(tmp2);
      });
    
      // Set the state with the aggregated data
      setBatiment(allBuildings);
      setPersonHour(allHours);
      ManagerFilterList(allPersons)
      return;
    });

    }
  }


  const getHistoryDate = (stateVar = state) => {
    let tmp = [];
    let tmp2 = [];
    let tmp3 = []

    const day = stateVar[0].startDate.getDate();
    const monthIndex = stateVar[0].startDate.getMonth();
    const year = stateVar[0].startDate.getFullYear();
    const myFormattedDate =
      year +
      '-' +
      (monthIndex + 1 >= 10 ? monthIndex + 1 : '0' + (monthIndex + 1)) +
      '-' +
      day;
    const day2 = stateVar[0].endDate.getDate();
    const monthIndex2 = stateVar[0].endDate.getMonth();
    const year2 = stateVar[0].endDate.getFullYear();
    const myFormattedDate2 =
      year2 +
      '-' +
      (monthIndex2 + 1 >= 10 ? monthIndex2 + 1 : '0' + (monthIndex2 + 1)) +
      '-' +
      day2;
    if (myFormattedDate == myFormattedDate2) {
      let req =  axios.get(
          DNS +
        '/api/history/?start=' + myFormattedDate,
          requestOptionsAxiosGet,
      )
      .then((response) => {
        let models = response.data[0]?.data
        let datadate = response.data[0]?.date
        console.log(datadate)
        models?.Building.map((elem) => {
          tmp = tmp.concat({'value': elem.id, 'label': elem.name});
        });
        models?.Hour.map((elem) => {
          tmp2 = tmp2.concat({'value': elem, 'label': elem.begin + ' - ' + elem.begin_max});
        });

        models?.Person.map((person) => {
          person.truedate = formatDateToFr(datadate)
          console.log("person:", person)
        })

        tmp.filter((building) => {
          tmp2.filter((hour) => {
          models?.Person.map((person) => {
            if (building.value == hour.value?.pkBuilding_id && hour.value.pkPerson_id == person.id) {
              person.building = building.label
          }
          if (hour.value?.pkPerson_id == person.id) {
            person.hour = hour.label
          }
        }
          )
        })
        })
        setBatiment(tmp);
        setPersonHour(tmp2)
        console.log("models.person: ", models?.Person )
        setList(
          models?.Person ? models?.Person : "" 
        );
        setOgList(models?.Person ? models?.Person : "" )
        return;

      })
    } else {
      let req =  axios.get(
        DNS +
      '/api/history/?start=' + myFormattedDate +
      '&end=' +
      myFormattedDate2,
      requestOptionsAxiosGet,
    )
    .then((response) => {
      // Initialize temporary arrays
      let allPersons = [];
      let allBuildings = [];
      let allHours = [];
    
      // Iterate through the entire response.data array
      response.data.forEach((dataChunk) => {
        let datadate = dataChunk?.date
        let models = dataChunk?.data;
    
        // Process Building and Hour data
        let tmp = [];
        let tmp2 = [];
        models?.Building.forEach((elem) => {
          tmp = tmp.concat({ value: elem.id, label: elem.name });
        });
        models?.Hour.forEach((elem) => {
          tmp2 = tmp2.concat({ value: elem, label: `${elem.begin} - ${elem.begin_max}` });
        });
    
        // Process Person data and associate with Building and Hour
        let persons = models?.Person.map((person) => {
          person.truedate = formatDateToFr(datadate)
          tmp.forEach((building) => {
            tmp2.forEach((hour) => {
              if (building.value === hour.value?.pkBuilding_id && hour.value.pkPerson_id === person.id) {
                person.building = building.label;
              }
              if (hour.value?.pkPerson_id === person.id) {
                person.hour = hour.label;
              }
            });
          });
          return person; // Return the modified person object
        });
    
        // Concatenate processed data to global arrays
        allPersons = allPersons.concat(persons || []);
        allBuildings = allBuildings.concat(tmp);
        allHours = allHours.concat(tmp2);
      });
    
      // Set the state with the aggregated data
      setBatiment(allBuildings);
      setPersonHour(allHours);
      setList(allPersons);
      setOgList(allPersons);
      return;
    });

    }
  }


//        '?start=' +
// myFormattedDate +
// '&end=' +
// myFormattedDate2,

  // const handleDateSelect = (item) => {
  //   const date = new Date(item); // Replace this with your Date object

  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based
  //   const day = String(date.getDate()).padStart(2, '0');

  //   const formattedDate = `${year}-${month}-${day}`;
  //   setDateSelector(formattedDate)
  //   console.log("handleDateSselect: ", formattedDate)
  //   getHistoryDate(formattedDate)
  //   setDateValue(item)
  // }




  const getTodayDate = () => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();


    today = yyyy + '-' + mm + '-' + dd;

    return (today)

  };


  const formatDateToFr = (date) => {
    let formattedDate = new Date(date);
    const dd = String(formattedDate.getDate()).padStart(2, '0');
    const mm = String(formattedDate.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = formattedDate.getFullYear();


    formattedDate = dd  + '-' + mm + '-' + yyyy;
    console.log('formatteddate : ', formattedDate)

    return (formattedDate)

  };


  let content = null;

  // if (alist.error) {
  //   content = <p>ERROR: couldn't load person list properly.</p>;
  // }

  const listFilter = [];
  const headers = [
    {label: 'Date', key: 'truedate'},
    {label: 'Prénom', key: 'first_name'},
    {label: 'Nom', key: 'last_name'},
    {label: 'Batiment', key: 'building'},
    {label: 'Plage horaire', key: 'hour'},
    {label: 'Arrivée ce matin', key: 'begin_time'},
    {label: 'Fin de journée', key: 'end_time'},
    {label: 'Code', key: 'confirm_code'},
  ];


  listFilter.push({date: dateSelector});


  if (alist.length > 0) {
    content = alist
        .slice(0)
        .reverse()
        .filter((val) => {
              val.date = dateSelector; 
              console.log("Val: ", val)
              if (searchTerm == '') {
                listFilter.push(val);
                return val;
              } else if (
                val.first_name.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                listFilter.push(val);
                return val;
              }  else if (
                val.last_name.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                listFilter.push(val);
                return val;
              } 
               else if (val.building && val.hour) {
               if (val.building.toLowerCase().includes(searchTerm.toLowerCase())) {
                listFilter.push(val);
                return val;
               }
               if (val.hour.includes(searchTerm)) {
                listFilter.push(val);
                return val;
               }
              }
  
        })
        .map((person, key) => (
          <tr id="pk" value={person.id} key={key}>
            <td>{person.truedate}</td>
            <td>{person.first_name}</td>
            <td>{person.last_name}</td>
            <td>{person.building}</td>
            <td>{person?.hour}</td>
            <td>{person.begin_time  == '00:00:00' ? '' : person.begin_time}</td>
            <td>{person.end_time == '00:00:00' ? '' : person.end_time}</td>
            <td>{person.confirm_code}</td>
            <td className="td-actions text-right">
            </td>
          </tr>
        ));
  }

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Historique</Card.Title>
              <p className="card-category">
                Consulter l'historique des présences
              </p>

              <div className="row" style={{paddingTop: '15px'}}>
              {showDisplayDateRange == 'none' ? (
                    <Button
                      className={'btn-round'}
                      onClick={() => setDisplayDateRange('block')}
                    >
                      Choisir la Date
                    </Button>
                  ) : (
                    <Button
                      className={'btn-round'}
                      onClick={() => setDisplayDateRange('none')}
                    >
                      Fermer le menu Date
                    </Button>
                  )}
                  <div
                    style={{
                      display: showDisplayDateRange,
                      position: 'absolute',
                      marginTop: '1.7%',
                      zIndex: 100,
                    }}
                  >
                    {user.role != "manager" ? <DateRange
                      editableDateInputs={true}
                      onChange={(item) => {
                        console.log(item.selection)
                        setState([item.selection]);
                        getHistoryDate([item.selection]);
                      }}
                      moveRangeOnFirstSelection={false}
                      ranges={state}
                      openCalendarOnFocus={false}
                      dateDisplayFormat="dd MMM yyyy"
                      locale={fr}
                    /> : 
                    <DateRange
                      editableDateInputs={true}
                      onChange={(item) => {
                        console.log(item.selection)
                        setState([item.selection]);
                        getManagerHistoryDate([item.selection]);
                      }}
                      moveRangeOnFirstSelection={false}
                      ranges={state}
                      openCalendarOnFocus={false}
                      dateDisplayFormat="dd MMM yyyy"
                      locale={fr}
                    />}
                  </div>
                <AsyncExcel
                  toExport={listFilter}
                  headers={headers}
                  sentence="Extraire les données"
                />
                {user.role != 'is_manager' ? <Button
                  className={'btn-round'}
                  onClick={() => setShowModal(true)}
                >
                  Filtrer les données
                </Button> : null}
                <div id="custom-search-input">
                  <div className="input-group col-md-12">
                    <input
                      type="text"
                      className="form-control ml-5"
                      placeholder="Rechercher"
                      onChange={(event) => {
                        setSearchTerm(event.target.value);
                      }}
                    />
                    <span className="input-group-btn btn btn-primary">
                      <span className=" fas fa-search"></span>
                    </span>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive">
              <Table className="table-hover table-striped">
                <thead>
                  <tr>
                    <th onClick={() => handleSortState('date')} style={{color: sortState == 'ReverseDate' ? "#ff0002" : sortState == 'date' ? "#5db5c7" : "#ffc106"}} className="border-0">date {sortState == 'ReverseDate' ? <i className="fas fa-arrow-down"></i> : sortState == 'date' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>} </th>
                    <th onClick={() => handleSortState('firstName')} style={{color: sortState == 'ReversefirstName' ? "#ff0002" : sortState == 'firstName' ? "#5db5c7" : "#ffc106"}} className="border-0">Prénom {sortState == 'ReversefirstName' ? <i className="fas fa-arrow-down"></i> : sortState == 'firstName' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>} </th>
                    <th onClick={() => handleSortState('lastName')} style={{color: sortState == 'ReverselastName' ? "#ff0002" : sortState == 'lastName' ? "#5db5c7" : "#ffc106"}} className="border-0">Nom {sortState == 'ReverselastName' ? <i className="fas fa-arrow-down"></i> : sortState == 'lastName' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    <th onClick={() => handleSortState('building')} style={{color: sortState == 'Reversebuilding' ? "#ff0002" : sortState == 'building' ? "#5db5c7" : "#ffc106"}} className="border-0">Batiment {sortState == 'Reversebuilding' ? <i className="fas fa-arrow-down"></i> : sortState == 'building' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    <th onClick={() => handleSortState('schedule')} style={{color: sortState == 'Reverseschedule' ? "#ff0002" : sortState == 'schedule' ? "#5db5c7" : "#ffc106"}} className="border-0">Plage horaire {sortState == 'Reverseschedule' ? <i className="fas fa-arrow-down"></i> : sortState == 'schedule' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    <th onClick={() => handleSortState('begin')} style={{color: sortState == 'Reversebegin' ? "#ff0002" : sortState == 'begin' ? "#5db5c7" : "#ffc106"}} className="border-0">Arrivée ce matin {sortState == 'Reversebegin' ? <i className="fas fa-arrow-down"></i> : sortState == 'begin' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    <th onClick={() => handleSortState('end')} style={{color: sortState == 'Reverseend' ? "#ff0002" : sortState == 'end' ? "#5db5c7" : "#ffc106"}} className="border-0">Fin de journée {sortState == 'Reverseend' ? <i className="fas fa-arrow-down"></i> : sortState == 'end' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    <th onClick={() => handleSortState('code')} style={{color: sortState == 'Reversecode' ? "#ff0002" : sortState == 'code' ? "#5db5c7" : "#ffc106"}} className="border-0">Code {sortState == 'Reversecode' ? <i className="fas fa-arrow-down"></i> : sortState == 'code' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    {sortState != 'neutral' ?
                    <div style={{marginLeft: "55%"}}>
                      <a
                      className="btn-sm btn-round ml-1 mr-1  btn-warning"
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        setSortState('neutral')
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </a>
                    </div>:
                      null
                    }
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        className="modal modal-primary"
        show={showModal}
        onHide={() => setShowModal(false)}
        // onSubmit={(e) => createPerson(e)}
        autoFocus={false}
        enforceFocus={false}
      >
        <Modal.Header className="justify-content-center">
          <div className="modal-profile">
            <i className="nc-icon nc-simple-add"></i>
          </div>
        </Modal.Header>
        <div className="modal-body">
          <p className="text-center"> Définir le batiment et l'heure à filtrer:</p>
          <Form noValidate validated={validated} onSubmit={''}>
            <Form.Group as={Row} controlId="formHorizontalPrénom">
              <Form.Label column sm={4}>
                Afficher le Batiment:
              </Form.Label>
              <Col sm={8}>
              <Select
                isClearable={true}
                isSearchable={true}
                components={animatedComponents}
                value={showBatimentSelected}
                options= {buildingList}
                onChange={(e) =>
                  setBatimentSelected(
                    e == null ?
                    '' :
                    e,
                  )
                }
              />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Button
                className="btn-simple col-sm-6"
                type="button"
                variant="link"
                onClick={() => setShowModal(false)}
              >
                Fermer
              </Button>
              <Button className="btn-fill btn-round col-sm-6" onClick={(e) => {filterList(e, showBatiment)}}>
                FILTRER{' '}
              </Button>{' '}
            </Form.Group>
          </Form>
        </div>
      </Modal>
    </Container>
  );
};

export default MyHistoryList;